import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import loader from "./loader";
import notification from "./notifications";
import searchValue from "./searchValue";

const reducer = combineReducers({
  loader,
  notification,
  searchValue,
});

const store = configureStore({
  reducer,
});

export default store;
