import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import styled from "styled-components";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";
import Button from "./components/Button/SmButton";
import useAuth from "./Hooks/useAuthHook";
import { useContext } from "react";
import AuthContext from "./context/Auth";

const RedBanner = styled.div`
  width: 100%;
  z-index: 112;
  font-size: 13px;
`;
const Overlay = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 112;
  cursor: pointer;
`;

function App() {
  let timer;
  //57 minutes
  const timeout = 3420000;
  //const timeout = 15000;
  const [remaining, setRemaining] = React.useState(timeout);
  const [isIdle, setIsIdle] = React.useState(false);
  const [overlayTrigger, setOverlayTrigger] = React.useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const { logout: authlogout } = useAuth();
  let { isAuthenticated } = useContext(AuthContext);

  const { reset, getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  const logout = () => {
    authlogout();
  };

  React.useEffect(() => {
    if (isAuthenticated && isIdle == true) {
      setOverlayTrigger(true);
      toast(
        <>
          <span>You will be logged out. Do you want to stay?</span>
          <div className="text-center mt-2">
            <Button
              type="button"
              onClick={() => {
                clearTimeout(timer);
                reset();
                toast.dismiss();
                setOverlayTrigger(false);
              }}
            >
              Keep me Logged In
            </Button>
          </div>
        </>,

        {
          className: "toast-position",
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeButton: false,
          draggable: true,
          progress: undefined,
        }
      );
      timer = setTimeout(() => {
        toast.dismiss();
        setOverlayTrigger(false);
        logout();
      }, 15000);
    }
  }, [isIdle]);

  React.useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    // setRemaining(getRemainingTime());

    // setInterval(() => {
    //   setRemaining(getRemainingTime());
    // }, 1000);
  }, []);

  return (
    <>
      {overlayTrigger && <Overlay></Overlay>}
      <div className="app_wrapper">
        <RedBanner className="bg-danger  font-weight-bold position-absolute ">
          <p className="text-center m-0 text-white">
            Compliance Cabinet Staging Server
          </p>
        </RedBanner>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
