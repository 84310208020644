import instance from "./axiosConfig";

function downloadFile({ fileName = "Reports.xlsx", path }) {
  let link = document.createElement("a");
  link.href = process.env.REACT_APP_BASE_URL + path;
  link.download = fileName;
  link.click();
}

export async function dismissNotifications() {
  let { data } = await instance.get(`/notification/dismiss-all`);
  return data;
}
export async function getAllNotifications() {
  let { data } = await instance.get(`/notification/all`);
  return data;
}
export async function markAsReadNotification(notificationId) {
  let { data } = await instance.get(
    `/notification/mark-read/${notificationId}`
  );
  return data;
}

export async function getNotificationPermissions() {
  let { data } = await instance.get(`/notification/user-permissions`);
  return data;
}
export async function unreadNotifications() {
  let { data } = await instance.get(`/notification/unread-notifications`);
  return data;
}

export async function setNotificationPermissions(_data) {
  let { data } = await instance.post(`/notification/user-permissions`, _data);
  return data;
}

export async function downloadClientReport({ clientId = "" }) {
  let { data } = await instance.get(
    `/reports/client-report-download/${clientId}`
  );
  downloadFile({
    fileName: `Company-${clientId}-Report.xlsx`,
    path: data?.path,
  });
  return data;
}
export async function downloadStateLoginsReport() {
  let { data } = await instance.get(`/reports/state-login-download`);
  downloadFile({ fileName: "State-Login-Report.xlsx", path: data?.path });
  return data;
}
export async function downloadClientLicenseLoginReport() {
  let { data } = await instance.get(`/reports/client-login-download`);
  downloadFile({ fileName: "Company-Login-Report.xlsx", path: data?.path });
  return data;
}

export async function downloadClientLicenseFile({ id = "" }) {
  let { data } = await instance.get(
    `/reports/client-licenses-download?clientId=${id}`
  );
  downloadFile({ fileName: "Company Licenses Report.xlsx", path: data?.path });
  return data;
}

export async function downloadClientLicenseByStateFile({ id = "" }) {
  let { data } = await instance.get(
    `/reports/client-licenses-by-state-download?state=${id}`
  );
  downloadFile({
    fileName: "Company Licenses By State Report.xlsx",
    path: data?.path,
  });
  return data;
}

export async function downloadClientLicenseBondFile(
  fileName = "Company Bond-Report.xlsx"
) {
  let { data } = await instance.get(`/reports/client-licenses-bond-download`);
  downloadFile({ fileName, path: data?.path });
  return data;
}

export async function downloadClientLicenseBondByStateFile(
  fileName = "Company-bond-by-state-Report.xlsx"
) {
  let { data } = await instance.get(
    `/reports/client-licenses-bond-by-state-download`
  );
  downloadFile({ fileName, path: data?.path });
  return data;
}

export async function downloadClientServiceScopeFile(
  fileName = "Company Service Scope-Report.xlsx"
) {
  let { data } = await instance.get(`/reports/client-services-scope-download`);
  downloadFile({ fileName, path: data?.path });
  return data;
}
export async function downloadClientMailingListFile(
  fileName = "specialists-Report.xlsx"
) {
  let { data } = await instance.get(`/reports/client-mailing-address-download`);
  downloadFile({ fileName, path: data?.path });
  return data;
}

export async function downloadSpeclistFile(
  fileName = "Specialists-Report.xlsx"
) {
  let { data } = await instance.get(`/reports/specialists-download`);
  downloadFile({ fileName, path: data?.path });
  return data;
}
export async function downloadStateBulkLoginReport() {
  let { data } = await instance.get(`/reports/state-bulk-logins-download`);
  downloadFile({ fileName: "State-Bulk-Logins", path: data?.path });
  return data;
}
export async function getClientReport(clientId) {
  let { data } = await instance.get(`/reports/client-report/${clientId}`);
  return data;
}
export async function getStateBulkLogins() {
  let { data } = await instance.get(`/reports/state-bulk-logins`);
  return data;
}

export async function getStateLoginsReport() {
  let { data } = await instance.get(`/reports/state-login`);
  return data;
}

export async function getClientLicenseForCredientials(clientId, stateId = "") {
  let { data } = await instance.get(
    `/license/client-license/${clientId}?stateId=${stateId}`
  );
  return data;
}

export async function getReportForCredentials(stateId = "") {
  let { data } = await instance.get(
    `/master-reports/credential-report/${stateId}`
  );
  return data;
}

export async function getClientLicenseLoginReport({
  func: { id: funcId = "" },
  freq: { id: freqId = "" },
}) {
  let { data } = await instance.get(
    `/reports/client-login?func=${funcId}&freq=${freqId}`
  );
  return data;
}
export async function getClientServiceScopeReport() {
  let { data } = await instance.get(`/reports/client-serivce-scope`);
  return data;
}
export async function getFrequency() {
  let { data } = await instance.get(`/license/frequency`);
  return data;
}
export async function deleteFrequency(frequencyId) {
  let { data } = await instance.delete(`/license/frequency/${frequencyId}`);
  return data;
}
export async function updateFrequency(_data) {
  let { data } = await instance.put(`/license/frequency`, _data);
  return data;
}

export async function updateUserUrlLocation(_data) {
  let { data } = await instance.put(`/socket/update-user-url`, _data);
  return data;
}

export async function createFrequency(_data) {
  let { data } = await instance.post(`/license/frequency`, _data);
  return data;
}
export async function getClientMailingAddressReport() {
  let { data } = await instance.get(`/reports/client-mailing-address`);
  return data;
}
export async function getClientLicenseBondByStateReport() {
  let { data } = await instance.get(`/reports/client-licenses-bond-by-state`);
  return data;
}
export async function getClientLicenseBondReport() {
  let { data } = await instance.get(`/reports/client-licenses-bond`);
  return data;
}

export async function getClientLicenseByStateReport({
  stateId: { id: stateId = "" },
  typeId: { id: typeId = "" },
}) {
  let { data } = await instance.get(
    `/reports/client-licenses-by-state?state=${stateId}&type=${typeId}`
  );
  return data;
}

export async function getClientLicenseReport({ id = "" }) {
  let { data } = await instance.get(`/reports/client-licenses?clientId=${id}`);
  return data;
}

export async function getSpecialistReport() {
  let { data } = await instance.get(`/reports/specialists`);
  return data;
}

export async function exportClientLicenses({
  ids,
  isDownload,
  email,
  fileName = "Company-Licenses-Report.xlsx",
}) {
  let { data } = await instance.post(`/clients/export-licenses`, {
    ids,
    isDownload,
    email,
  });

  if (isDownload && data?.path) {
    downloadFile({ fileName, path: data?.path });
  }

  return data;
}

export async function exportContactsFile(
  query = "",
  fileName = "Contact-Report.xlsx"
) {
  let { data } = await instance.get(`/contacts/export-data?query=${query}`);
  downloadFile({ fileName, path: data?.path });
  return data;
}
export async function getAllUsers(flag = "") {
  let { data } = await instance.get(`/users?flag=${flag}`);
  return data;
}
export async function updateProfile(_data) {
  let { data } = await instance.post("/users/update-profile", _data);
  return data;
}

export async function updateUserStatus(userId, status) {
  let { data } = await instance.get(`/users/status/${userId}?status=${status}`);
  return data;
}
export async function updateRole(_data) {
  let { data } = await instance.put(`/users/roles/${_data.id}`, _data);
  return data;
}

export async function updateUser(_data) {
  let { data } = await instance.put(`/users/${_data.id}`, _data);
  return data;
}
export async function createUser(_data) {
  let { data } = await instance.post("/users", _data);
  return data;
}
export async function checkDuplicateEmail(email, id = null) {
  let { data } = await instance.post("/users/verify-email", { email, id });
  return data;
}
export async function createRole(_data) {
  let { data } = await instance.post("/users/roles", _data);
  return data;
}

export async function getRoles() {
  let { data } = await instance.get("/users/roles");
  return data;
}
export async function removeRole(id) {
  let { data } = await instance.delete(`/users/roles/${id}`);
  return data;
}

export async function removeUser(id) {
  let { data } = await instance.delete(`/users/${id}`);
  return data;
}

export async function getAllPermission() {
  let { data } = await instance.get("/users/permissions");
  return data;
}

export async function LLCManagement() {
  let { data } = await instance.get("/llc_managements");
  return data;
}
export async function userInfo() {
  return await instance.get("https://dev-1mjsdo5o.us.auth0.com/userinfo");
}
export async function loginWithAuth0(_data) {
  return await instance.post(
    "https://dev-1mjsdo5o.us.auth0.com/oauth/token",
    _data
  );
}

export async function setNewPassword(_data) {
  let { data } = await instance.post("/auth/set-new-password", _data);
  return data;
}
export async function logout(_data) {
  let { data } = await instance.post("/auth/logout");
  return data;
}

export async function updatePasswordUsingToken(_data) {
  let { data } = await instance.post("/auth/update-password", _data);
  return data;
}

export async function checkLogin(_data) {
  let { data } = await instance.post("/auth/logout", _data);
  return data;
}
export async function sendForgotPasswordLink(_data) {
  let { data } = await instance.post("/auth/forgot-password", _data);
  return data;
}

export async function checkPassword(_data) {
  let { data } = await instance.post("/contacts/check-password", _data);
  return data;
}
export async function login(_data) {
  let { data } = await instance.post("/auth/login", _data);
  return data;
}

export async function taxTypes() {
  let { data } = await instance.get("/taxes");
  return data;
}

export async function geLogs(
  size = "",
  filterDate = "",
  {
    clientName: { entity_name: clientName = "" },
    userName: { name: userName = "" },
  }
) {
  let { data } = await instance.get(
    `/general/logs?size=${size}&client=${clientName}&user=${userName}&date=${filterDate}`
  );
  return data;
}

export async function getLogsClient() {
  let { data } = await instance.get(`/general/logs/getClients`);
  return data;
}

export async function getLogsUser() {
  let { data } = await instance.get(`/general/logs/getUsers`);
  return data;
}

export async function operationTypes() {
  let { data } = await instance.get("/operations");
  return data;
}

export async function services() {
  let { data } = await instance.get("/services");
  return data;
}

export async function getAllTotal() {
  let { data } = await instance.get("/general/total");
  return data;
}

export async function DHWCSpecialist() {
  let { data } = await instance.get("/dhwc_specialists");
  return data;
}

export async function countries() {
  let { data } = await instance.get("/countries");
  return data;
}
export async function statesByCountryId({ queryKey: [_, _data] }) {
  let { data } = await instance.get(`/states?country_id=${_data.countryId}`);
  return data;
}

export async function checkDuplicateEin({ queryKey: [_, _data] }) {
  let { data } = await instance.get(`/clients/duplicate_ein?ein=${_data.ein}`);
  return data;
}

export async function getDuplicateDataByFirstName({ queryKey: [_, _data] }) {
  let { data } = await instance.get(
    `/clients/contact/duplicate_data?fname=${_data.name}`
  );
  return data;
}

export async function getDuplicateDataByLastName({ queryKey: [_, _data] }) {
  let { data } = await instance.get(
    `/clients/contact/duplicate_data?lname=${_data.name}`
  );
  return data;
}

export async function locationByAgencyId({ queryKey: [_, _data] }) {
  let { data } = await instance.get(
    `/agencies-location?agencyId=${_data.agencyLocationId}`
  );
  return data;
}

export async function createClient(_data) {
  let { data } = await instance.post(`/clients`, _data);
  return data;
}

export async function removeCredentials(clientId, recordId) {
  let { data } = await instance.delete(
    `/clients/credentials/${clientId}/${recordId}`
  );
  return data;
}

export async function getClientCredentials(clientId) {
  let { data } = await instance.get(`/clients/credentials/${clientId}`);
  return data;
}
export async function createClientCredentials(_data) {
  let { data } = await instance.post(
    `/clients/credentials/${_data?.clientId}`,
    _data
  );
  return data;
}
export async function updateClientCredentials(_data) {
  let { data } = await instance.put(
    `/clients/credentials/${_data?.clientId}`,
    _data
  );
  return data;
}

export async function getClients({
  colorId: { id: colorId = "" } = "",
  typeId: { id: typeId = "" } = "",
}) {
  let { data } = await instance.get(`/clients?color=${colorId}&type=${typeId}`);
  return data;
}

export async function getClientsExcludingId(id) {
  let { data } = await instance.get(`/clients/excluding-id/${id}`);
  return data;
}

export async function agencyClient(_data) {
  let { data } = await instance.post(`/agencies/agency-client`, _data);
  return data;
}
export async function updateAgencyClient(_data) {
  let { data } = await instance.put(`/agencies/agency-client`, _data);
  return data;
}
export async function getAgencies({ id = "" }) {
  let { data } = await instance.get(`/agencies?state=${id}`);
  return data;
}

export async function getAgenciesWithFilter(filter) {
  let { data } = await instance.get(`/agencies?filter=${filter}`);
  return data;
}
export async function createAgencyContact(_data) {
  let { data } = await instance.post(
    `/agencies/contact/${_data.agencyId}`,
    _data
  );
  return data;
}

export async function deleteAgencyContact(
  agencyId,
  id,
  agencyName,
  contactName
) {
  let { data } = await instance.delete(
    `/agencies/contact/${agencyId}/${id}?agencyName=${agencyName}&contactName=${contactName}`
  );
  return data;
}
export async function updateAgencyContact(_data) {
  let { data } = await instance.put(
    `/agencies/contact/${_data.agencyId}`,
    _data
  );
  return data;
}

export async function getAgnecyContactById(agencyId, contactId) {
  let { data } = await instance.get(
    `/agencies/contact/agency-contact/${contactId}`
  );
  return data;
}

export async function getAgencyContacts(agencyId) {
  let { data } = await instance.get(`/agencies/contact/${agencyId}`);
  return data;
}

export async function getAgencyWithLocations(agencyId) {
  let { data } = await instance.get("/agencies/locations/" + agencyId);
  return data;
}
export async function getContactTypes() {
  let { data } = await instance.get(`/contact_types`);
  return data;
}

export async function getSal() {
  let { data } = await instance.get(`/sals`);
  return data;
}
export async function getSuffix() {
  let { data } = await instance.get(`/suffix`);
  return data;
}
export async function maritalStatus() {
  let { data } = await instance.get(`/marital_status`);
  return data;
}

export async function createContact(_data) {
  let { data } = await instance.post(`/contacts`, _data);
  return data;
}
export async function getContactsLinkedWithEmail(email) {
  let { data } = await instance.post(`/contacts/contact-by-email`, { email });
  return data;
}
export async function getContacts(type, { id = "" }) {
  let { data } = await instance.get(`/contacts?type=${type}&color=${id}`);
  return data;
}

export async function getContactById(id, isFull = false) {
  let { data } = await instance.get(`/contacts/${id}?isFull=${isFull}`);
  return data;
}

export async function getContactByIdForNonedit(id, isFull = false) {
  let { data } = await instance.get(`/contacts/${id}?isFull=${isFull}`);
  return data;
}

export async function getContactAddressesById(id) {
  let { data } = await instance.get(`/contacts/${id}/addresses`);
  return data;
}

export async function createContactAddress(_data) {
  let { data } = await instance.post(`/addresses`, _data);
  return data;
}
export async function updateContactAddress(_data) {
  let { data } = await instance.put(`/addresses/${_data.id}`, _data);
  return data;
}

export async function updateTag(_data) {
  if (_data.workstop) {
    let { data } = await instance.put(
      `/clients/workstop-tag/${_data.id}`,
      _data
    );
    return data;
  } else {
    let { data } = await instance.put(`/clients/tag/${_data.id}`, _data);
    return data;
  }
}

export async function updateContactTag(_data) {
  if (_data.workstop) {
    let { data } = await instance.put(
      `/contacts/workstop-tag/${_data.id}`,
      _data
    );
    return data;
  } else {
    let { data } = await instance.put(`/contacts/tag/${_data.id}`, _data);
    return data;
  }
}

export async function updateContactEmployment(_data) {
  let { data } = await instance.put(`/employments/${_data.id}`, _data);
  return data;
}

export async function createContactEmployment(_data) {
  let { data } = await instance.post(`/employments`, _data);
  return data;
}

export async function getContactEmploymentById(_id) {
  let { data } = await instance.get(`/contacts/${_id}/employments`);

  return data;
}

export async function removeAddress(id) {
  let { data } = await instance.delete(`/addresses/${id}`);
  return data;
}

export async function removeEmployment(id) {
  let { data } = await instance.delete(`/employments/${id}`);
  return data;
}

export async function updateContact(_data) {
  let { data } = await instance.put(`/contacts/${_data.id}`, _data);
  return data;
}

export async function deleteContact(id, contactName) {
  let { data } = await instance.delete(
    `/contacts/${id}?contactName=${contactName}`
  );
  return data;
}

export async function removeClient(id, clientName) {
  let { data } = await instance.delete(`/clients/${id}?name=${clientName}`);
  return data;
}
export async function updateClient(_data) {
  let { data } = await instance.put(`/clients/${_data.id}`, _data);
  return data;
}
export async function addToFav(id, val) {
  let { data } = await instance.get(`/clients/favourite/${id}?val=${val}`);
  return data;
}
export async function getClientById(id) {
  let { data } = await instance.get(`/clients/${id}`);
  return data;
}
export async function getClientByIdForEdit(id) {
  let { data } = await instance.get(`/clients/detail/${id}`);
  return data;
}

export async function createAgency(_data) {
  let { data } = await instance.post(`/agencies`, _data);
  return data;
}
export async function getAgencyById(_id) {
  let { data } = await instance.get(`/agencies/${_id}`);
  return data;
}

export async function deleteAgencyById(_data) {
  let { data } = await instance.delete(
    `/agencies/${_data.id}?name=${_data.name}`
  );
  return data;
}

export async function getAgencyAddresses(agencyId) {
  let { data } = await instance.get(`/agencies-location/${agencyId}`);
  return data;
}

export async function getMasterReprots() {
  let { data } = await instance.get(`/master-reports`);
  return data;
}
export async function createAgencyAddress(_data) {
  let { data } = await instance.post(
    `/agencies-location/${_data.agencyId}`,
    _data
  );
  return data;
}
export async function getAgency(id) {
  let { data } = await instance.get(`/agencies/edit-agency/${id}`);
  return data;
}
export async function updateAgency(_data) {
  let { data } = await instance.put(`/agencies/${_data.id}`, _data);
  return data;
}

export async function removeAgencyAddress(id, agencyName, addressLocation) {
  let { data } = await instance.delete(
    `/agencies-location/${id}?agencyName=${agencyName}&location=${addressLocation}`
  );
  return data;
}

export async function removeMasterReport(id, name) {
  let { data } = await instance.delete(`/master-reports/${id}?name=${name}`);
  return data;
}

export async function updateAgencyAddress(_data) {
  let { data } = await instance.put(`/agencies-location/${_data.id}`, _data);
  return data;
}

export async function createClientAddress(_data) {
  let { data } = await instance.post(
    `/clients/address/${_data.clientId}`,
    _data
  );
  return data;
}

export async function createClientOwnership(_data) {
  let { data } = await instance.post(
    `/clients/ownership/${_data.clientId}`,
    _data
  );
  return data;
}

export async function createClientPrincipalOwnership(_data) {
  let { data } = await instance.post(
    `/clients/principal/ownership/${_data.clientId}/${_data.principalId}`,
    _data
  );
  return data;
}

export async function createClientReport(_data) {
  let { data } = await instance.post(
    `/clients/report/${_data.clientId}`,
    _data
  );
  return data;
}

export async function getClientAddresses(id) {
  let { data } = await instance.get(`/clients/address/${id}`);
  return data;
}
export async function getClientAddress(id) {
  let { data } = await instance.get(`/clients/client-address/${id}`);
  console.log("api", data);
  return data;
}
export async function getClientOwnership(id) {
  let { data } = await instance.get(`/clients/ownership/${id}`);
  return data;
}
export async function getClientOwners(id) {
  let { data } = await instance.get(`/clients/owners/${id}`);
  return data;
}

export async function updateClientAddress(_data) {
  let { data } = await instance.put(
    `/clients/address/${_data.clientId}`,
    _data
  );
  return data;
}

export async function updateClientOwnership(_data) {
  let { data } = await instance.put(
    `/clients/ownership/${_data.clientId}`,
    _data
  );
  return data;
}

export async function updateClientPrincipalOwnership(_data) {
  let { data } = await instance.put(
    `/clients/principal/ownership/${_data.clientId}/${_data.principalId}`,
    _data
  );
  return data;
}

export async function updateClientReport(_data) {
  let { data } = await instance.put(`/clients/report/${_data.clientId}`, _data);
  return data;
}
export async function removeClientAddress(clientId, id, clientName) {
  let { data } = await instance.delete(
    `/clients/address/${clientId}/${id}?clientName=${clientName}`
  );
  return data;
}
export async function removeClientOwnership(
  clientId,
  id,
  clientName,
  ownershipName
) {
  let { data } = await instance.delete(
    `/clients/ownership/${clientId}/${id}?clientName=${clientName}&ownershipName=${ownershipName}`
  );
  return data;
}
export async function removeClientReport(clientId, id) {
  let { data } = await instance.delete(`/clients/report/${clientId}/${id}`);
  return data;
}
export async function getClientAgencies(clientId) {
  let { data } = await instance.get(`/clients/agencies/${clientId}`);
  return data;
}
export async function removeAgencyFromClient(recordId, clientId) {
  let { data } = await instance.delete(
    `/clients/agencies/${clientId}/${recordId}`
  );
  return data;
}

export async function getAgencyClients(id) {
  let { data } = await instance.get(`/agencies/clients/${id}`);
  return data;
}

export async function getLicenseFunctions() {
  let { data } = await instance.get(`/license/function`);
  return data;
}

export async function getLicenseTypes() {
  let { data } = await instance.get(`/license/types`);
  return data;
}

export async function getAgenciesByStateId(stateId = "") {
  let { data } = await instance.get(`/agencies/state/${stateId}`);
  return data;
}

export async function createLicense(_data) {
  let { data } = await instance.post(`/license`, _data);
  return data;
}

export async function getAllLicenses({
  stateId: { id: stateId = "" },
  typeId: { id: typeId = "" },
}) {
  let { data } = await instance.get(`/license?state=${stateId}&type=${typeId}`);
  return data;
}

export async function getLicenseById(id, isFull = "") {
  let { data } = await instance.get(`/license/${id}?detail=${isFull}`);
  return data;
}

export async function updateLicense(_data) {
  let { data } = await instance.put(`/license/${_data.id}`, _data);
  return data;
}

export async function removeLicense(_id, name) {
  let { data } = await instance.delete(`/license/${_id}?name=${name}`);
  return data;
}
export async function getAgencyLicense(id) {
  let { data } = await instance.get(`/agencies/licenses/${id}`);
  return data;
}

export async function getContactsForSearchBox(type) {
  let { data } = await instance.get(
    `/clients/contacts-for-search?type=${type}`
  );
  return data;
}

export async function createClientContact(_data) {
  let { data } = await instance.post(
    `/clients/contact/${_data.clientId}`,
    _data
  );
  return data;
}
export async function getContactsByClientId(clientId) {
  let { data } = await instance.get(`/clients/contact/${clientId}`);
  return data;
}

export async function updateClientContact(_data) {
  let { data } = await instance.put(
    `/clients/contact/${_data.clientId}`,
    _data
  );
  return data;
}

export async function createNote(_data) {
  let { data } = await instance.post(`/clients/note/${_data.clientId}`, _data);
  return data;
}

export async function updateNote(_data) {
  let { data } = await instance.put(
    `/clients/note/${_data.clientId}/${_data.id}`,
    _data
  );
  return data;
}

export async function createContactNote(_data) {
  let { data } = await instance.post(
    `/contacts/note/${_data.contactId}`,
    _data
  );
  return data;
}

export async function updateContactNote(_data) {
  let { data } = await instance.put(
    `/contacts/note/${_data.contactId}/${_data.id}`,
    _data
  );
  return data;
}

export async function removeNote(clientId, recordId, clientName) {
  let { data } = await instance.delete(
    `/clients/note/${clientId}/${recordId}?clientName=${clientName}`
  );
  return data;
}
export async function getNotes(clientId) {
  let { data } = await instance.get(`/clients/note/${clientId}`);
  return data;
}

export async function removeContactNote(contactId, recordId, contactName) {
  let { data } = await instance.delete(
    `/contacts/note/${contactId}/${recordId}?contactName=${contactName}`
  );
  return data;
}
export async function getContactNotes(contactId) {
  let { data } = await instance.get(`/contacts/note/${contactId}`);
  return data;
}

export async function getLicenseStatus() {
  let { data } = await instance.get(`/license/status`);
  return data;
}
export async function deleteClientContact(
  clinetId,
  id,
  clientName,
  contactName
) {
  let { data } = await instance.delete(
    `/clients/contact/${clinetId}/${id}?clientName=${clientName}&contactName=${contactName}`
  );
  return data;
}

export async function getContactByIdForClientForm(clientId, _id) {
  let { data } = await instance.get(`/clients/contact/${clientId}/${_id}`);
  return data;
}

export async function getOwnershipById(_id) {
  let { data } = await instance.get(`/clients/client-ownership/${_id}`);
  return data;
}

export async function getLicenseAlongAgency(_data) {
  let query = `stateId=${_data?.agency_id || ""}&funcId=${_data?.funcId || ""}`;
  let { data } = await instance.get(`/license/state?${query}`);
  return data;
}
export async function dashboardData() {
  let { data } = await instance.get(`/general/data`);
  return data;
}
export async function createClientLicense(_data) {
  let { data } = await instance.post(`/license/clients`, _data);
  return data;
}

export async function updateClientLicense(_data) {
  let { data } = await instance.put(`/license/clients`, _data);
  return data;
}

export async function getBondManaged() {
  let { data } = await instance.get(`/license/bond-managed`);
  return data;
}

export async function getBondtypes() {
  let { data } = await instance.get(`/license/bond-types`);
  return data;
}

export async function removeClientLicense(id, clientName, licenseName) {
  let { data } = await instance.delete(
    `/license/clients/${id}?clientName=${clientName}&licenseName=${licenseName}`
  );
  return data;
}

export async function getClientStatuses() {
  let { data } = await instance.get(`/clients/status`);
  return data;
}

export async function getClientLicense({ id = "" }, clientId) {
  let { data } = await instance.get(`/clients/license/${clientId}?type=${id}`);
  return data;
}
export async function getClientReportSetting(clientId) {
  let { data } = await instance.get(`/clients/report-setting/${clientId}`);
  return data;
}

export async function getClientReportSettingFrequency(_data) {
  let { data } = await instance.post(
    `/clients/report-setting-frequency`,
    _data
  );
  return data;
}

export async function getClientLicenseById(id) {
  let { data } = await instance.get(`/clients/license-by-id/${id}`);
  return data;
}

export async function getLicenseClients(id) {
  let { data } = await instance.get(`/license/clients/${id}`);
  return data;
}

export async function getReportFunctions() {
  let { data } = await instance.get(`/master-reports/function`);
  return data;
}

export async function getReportTypes() {
  let { data } = await instance.get(`/master-reports/types`);
  return data;
}

export async function createMasterReport(_data) {
  let { data } = await instance.post(`/master-reports`, _data);
  return data;
}

export async function updateMasterReport(_data) {
  let { data } = await instance.put(`/master-reports/${_data?.id}`, _data);
  return data;
}
